.service-container {
  padding: 8% 5%;
}

.service-header {
  font-size: 3rem;
}

.service-title h5 {
  font-size: 1.3rem;
  font-family: "Libre Baskerville", serif;
}

.services-image {
  width: 70%;
}

.service-body h5 {
  font-size: 16px;
}
