.footer-section {
}

.footer-text {
}

.footer-text h1 {
  font-weight: 400;
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

.copyright {
  font-size: 0.9rem;
  text-align: center;
  padding: 5% 0% 2%;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.copyright a {
  text-decoration: none;
  color: #000000;
  font-size: 13px;
}

.copyright a:hover {
  font-weight: bold;
}
