.homepage-container {
}

.homepage-image {
  width: 80%;
}

.heading h1 {
  font-size: 1.6em;
  padding: 5%;
}

.button {
  font-size: 20px;
  border: none;
}

.button-container {
  padding-bottom: 16%;
}

.image {
  background: url("../img/download.png");
  position: relative;
  height: 50vh;
  margin-bottom: 2px;
  filter: opacity(0.9);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.image2 {
  background: url("../img/download (1).png");
  position: relative;
  height: 50vh;
  filter: opacity(0.9);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  .heading h1 {
    font-size: 3em;
    padding: 5%;
  }

  .image {
    background: url("../img/download.png");
    position: relative;
    height: 50vh;
    margin-bottom: 0px;
    filter: opacity(0.9);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .button-container {
    padding-bottom: 8%;
  }
}
