.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none;
}

.nav-logo {
  font-size: 1rem;
  padding-left: 2%;
  font-weight: bold;
  font-family: "DM Sans", sans-serif;
}

.nav-logo p {
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  font-weight: normal;
  text-transform: uppercase;
}

.navbar-nav {
  justify-content: end;
  width: 100%;
  padding-right: 5%;
  font-size: 1.2em;
  font-family: "DM Sans", sans-serif;
}
