.about-container {
  padding: 5% 5%;
  background-color: #fafafa;
}

.about-header {
  font-size: 1.3rem;
  font-family: "Libre Baskerville", serif;
}

.about-body {
  font-family: "EB Garamond", serif;
}

.about-facts li {
  font-size: 0.9rem;
}
