.contact-container {
  padding: 5% 5% 0;
}

.contact-header {
  font-size: 2em;
  line-height: 3px;
}

.contact-info .icons {
  font-size: 0.9rem;
  padding-right: 2%;
}

.contact-info a {
  text-decoration: none;
  color: #1c1c1c;
}
.form-body-text {
  padding: 5% 3% 0%;
}

@media screen and (min-width: 768px) {
  .contact-header {
    font-size: 3em;
  }
}
